import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import ProtectedRoute from './ProtectedRoute';
import PageForHidden from 'views/pageforhidden';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/admin/dashboard/Default')));
const PageNotFound = Loadable(lazy(() => import('views/pageNotFound')));

const CustomerPage = Loadable(lazy(() => import('views/admin/customers')));
const FormCustomerPage = Loadable(lazy(() => import('views/admin/form-customer')));

const UserPage = Loadable(lazy(() => import('views/admin/users')));
const FormUserPage = Loadable(lazy(() => import('views/admin/form-admin')));

const OrderPage = Loadable(lazy(() => import('views/admin/orders')));
const ViewOrderPage = Loadable(lazy(() => import('views/admin/viewOrder')));

const FormProfile = Loadable(lazy(() => import('views/admin/form-profile')));

const MasterLensa = Loadable(lazy(() => import('views/admin/master-lensa')));
const MasterCoatingPage = Loadable(lazy(() => import('views/admin/master-coating')));
const MasterOptikPage = Loadable(lazy(() => import('views/admin/master-optik')));
const MasterIndeksPage = Loadable(lazy(() => import('views/admin/master-indeks')));
const MasterLensGroup = Loadable(lazy(() => import('views/admin/master-lens-group')));
const MasterTintingColorPage = Loadable(lazy(() => import('views/admin/master-color-tinting')));
const MasterTintingDensityPage = Loadable(lazy(() => import('views/admin/master-color-density')));
const MasterTintingGroupPage = Loadable(lazy(() => import('views/admin/master-color-group')));
const MasterOptikGroupPage = Loadable(lazy(() => import('views/admin/master-optik-group')));

const FormMasterLensaPage = Loadable(lazy(() => import('views/admin/form-master-lensa')));
const FormMasterCoatingPage = Loadable(lazy(() => import('views/admin/form-master-coating')));
const FormMasterOptikPage = Loadable(lazy(() => import('views/admin/form-master-optik')));
const FormMasterIndeksPage = Loadable(lazy(() => import('views/admin/form-master-indeks')));
const FormMasterLensGroupPage = Loadable(lazy(() => import('views/admin/form-master-lens-group')));
const FormMasterTintingColorPage = Loadable(lazy(() => import('views/admin/form-master-tinting-color')));
const FormMasterTintingDensityPage = Loadable(lazy(() => import('views/admin/form-master-tinting-density')));
const FormMasterTintingGroupPage = Loadable(lazy(() => import('views/admin/form-master-tinting-group')));
const FormMasterOptikGroupPage = Loadable(lazy(() => import('views/admin/form-master-optik-group')));

const FormOrder = Loadable(lazy(() => import('views/admin/form-orders')));
// ==============================|| MAIN ROUTING ||============================== //

const Admin = {
    path: '/admin',
    element: <ProtectedRoute component={<MainLayout />} roles="admin" />,
    children: [
        {
            path: '/',
            element: <Navigate to="/admin/manage-admin" />
        },
        {
            path: '/profile',
            element: <FormProfile formType="admin" />
        },
        {
            path: '/manage-admin',
            children: [
                {
                    path: '/',
                    element: <UserPage />
                },
                {
                    path: '/add',
                    element: <FormUserPage formType="create" />
                },
                {
                    path: 'edit/:adminId',
                    element: <FormUserPage formType="edit" />
                }
            ]
        },
        {
            path: '/orders',
            children: [
                {
                    path: '/',
                    element: <OrderPage />
                },
                {
                    path: '/create',
                    element: <FormOrder formType="create" />
                },
                {
                    path: '/:id',
                    element: <ViewOrderPage formType="view" />
                },
                {
                    path: '/edit/:id',
                    element: <ViewOrderPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data',
            element: <Navigate to="/admin/master-data/lensa" />
        },
        {
            path: '/master-data/account-optik',
            children: [
                {
                    path: '/',
                    element: <CustomerPage />
                },
                {
                    path: '/add',
                    element: <FormCustomerPage formType="create" />
                },
                {
                    path: '/edit/:adminId',
                    element: <FormCustomerPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/lensa',
            children: [
                {
                    path: '/',
                    element: <MasterLensa />
                },
                {
                    path: '/add',
                    element: <FormMasterLensaPage formType="create" />
                },
                {
                    path: '/edit/:lensaId',
                    element: <FormMasterLensaPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/optik',
            children: [
                {
                    path: '/',
                    element: <MasterOptikPage />
                },
                {
                    path: '/add',
                    element: <FormMasterOptikPage formType="create" />
                },
                {
                    path: '/edit/:optikId',
                    element: <FormMasterOptikPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/optik-group',
            children: [
                {
                    path: '/',
                    element: <MasterOptikGroupPage />
                },
                {
                    path: '/add',
                    element: <FormMasterOptikGroupPage formType="create" />
                },
                {
                    path: '/edit/:optikId',
                    element: <FormMasterOptikGroupPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/coating',
            children: [
                {
                    path: '/',
                    element: <MasterCoatingPage />
                },
                {
                    path: '/add',
                    element: <FormMasterCoatingPage formType="create" />
                },
                {
                    path: '/edit/:coatingId',
                    element: <FormMasterCoatingPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/tinting-color',
            children: [
                {
                    path: '/',
                    element: <MasterTintingColorPage />
                },
                {
                    path: '/add',
                    element: <FormMasterTintingColorPage formType="create" />
                },
                {
                    path: '/edit/:colorId',
                    element: <FormMasterTintingColorPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/tinting-density',
            children: [
                {
                    path: '/',
                    element: <MasterTintingDensityPage />
                },
                {
                    path: '/add',
                    element: <FormMasterTintingDensityPage formType="create" />
                },
                {
                    path: '/edit/:densityId',
                    element: <FormMasterTintingDensityPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/tinting-group',
            children: [
                {
                    path: '/',
                    element: <MasterTintingGroupPage />
                },
                {
                    path: '/add',
                    element: <FormMasterTintingGroupPage formType="create" />
                },
                {
                    path: '/edit/:groupId',
                    element: <FormMasterTintingGroupPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/indeks',
            children: [
                {
                    path: '/',
                    element: <MasterIndeksPage />
                },
                {
                    path: '/add',
                    element: <FormMasterIndeksPage formType="create" />
                },
                {
                    path: '/edit/:indexId',
                    element: <FormMasterIndeksPage formType="edit" />
                }
            ]
        },
        {
            path: '/master-data/lens-group',
            children: [
                {
                    path: '/',
                    element: <MasterLensGroup />
                },
                {
                    path: '/add',
                    element: <FormMasterLensGroupPage formType="create" />
                },
                {
                    path: '/edit/:lensId',
                    element: <FormMasterLensGroupPage formType="edit" />
                }
            ]
        },
        {
            path: '/403',
            element: <PageForHidden />
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]
};

export default Admin;
